import { config } from "./config";
import { getStaticJson } from "./static";

export interface BannerData {
  banners: Banner[];
}

export type Banner = {
  id: string;
  imageUrl: string;
  productId?: string;
  start_date?: string;
  end_date?: string;
  type?: string;
  categoryName?: string;
  link?: string;
};

export async function getBanners(partnerName?: string): Promise<BannerData> {
  if (!partnerName || partnerName === "hubble") {
    return getStaticJson(config.bannerPath);
  }

  let banners: BannerData;

  try {
    banners = await getStaticJson(
      `${config.clientBannerBasePath}/${partnerName}/${config.clientBannerFileName}`
    );
  } catch (e) {
    banners = { banners: [] };
  }

  return banners;
}
